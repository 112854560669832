import { Endpoint } from "../types/MenuTypes";

export const endpoint:Endpoint =  {
auth:'auth/',
category:'categories',
scripts:'scripts',
reports:'reports',
reportschedules:'reports/schedules',
search:'search?query=',
summaries:"summaries"
}